import { captureMessage, init } from "@sentry/gatsby";

import { isMonitoringEnabled } from "../../utils/featureFlags";
import { isNumber } from "../../utils/types";

const FILTERED_ERRORS = [
  {
    name: "NotAllowedError",
    message: "Permission to join interest group denied",
  },
];

const shouldFilterError = (error) => {
  return FILTERED_ERRORS.some((filter) => {
    if (filter.name) {
      if (error.name !== filter.name) {
        return false;
      }
    }

    if (filter.message) {
      if (!error.message.includes(filter.message)) {
        return false;
      }
    }

    return true;
  });
};

export class SentryService {
  static #isInitialized = false;

  static init() {
    if (typeof window === "undefined" || this.#isInitialized) {
      return;
    }

    const sampleRate = +process.env.GATSBY_SENTRY_SAMPLE_RATE;
    const tracesSampleRate = isNumber(sampleRate) ? sampleRate : 0.01;

    init({
      tracesSampleRate,
      dsn: process.env.GATSBY_SENTRY_DSN,
      attachStacktrace: true,
      enabled: isMonitoringEnabled(),
      denyUrls: [
        /stats\.g\.doubleclick\.net/,
        /googletagmanager\.com\/gtm\.js/,
        /cdn\.cookielaw\.org/,
        /tdn\.r42tag\.com/,
      ],
      beforeSend(event, hint) {
        if (hint?.originalException instanceof Error) {
          if (shouldFilterError(hint.originalException)) {
            return null;
          }
        }

        return event;
      },
    });

    this.#isInitialized = true;
  }

  static captureError({ message, context }) {
    captureMessage(message, {
      level: "error",
      extra: { context },
    });
  }
}
