/**
 * Display a custom message once your service worker finds an update
 * The following code will display a confirm prompt asking the user whether they
 * would like to refresh the page when an update is found.
 */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { SentryService } from "./src/services/sentry";

export const onServiceWorkerUpdateReady = () => {
  if (process.env.GATSBY_ENV !== "dev") return;
  const answer = window.confirm("This application has been updated. Reload to display the latest version?");
  if (answer === true) {
    window.location.reload();
  }
};

export const onClientEntry = () => {
  SentryService.init();
};
