const isMonitoringEnabled = () =>
  ["production"].includes(process.env.GATSBY_ENV) && !process.env.GATSBY_IS_MONITORING_DISABLED;
const isLivePreviewEnabled = ["dev"].includes(process.env.GATSBY_ENV);
const isBetaVersionEnabled = !!process.env.GATSBY_PATH_PREFIX;

module.exports = {
  isMonitoringEnabled,
  isLivePreviewEnabled,
  isBetaVersionEnabled,
};
